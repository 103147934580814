
export default {
    name: 'AdminTagsWidget',
    props: {
        'name': String,
		'available': Array,
		'chosen': Array,
		'allow_new': Boolean,
		'new_tags': Array,
	},
	methods: {
	    getAvailableTagsText: function() {
            /*
            With available objects list, make and return an array its texts,
            e.g. ["Tag1", "Tag2"]
            */
            return $.map(this.available, function(tag) {
                return tag.text
            });
        },
        getChosenTagsText: function() {
            /*
            With chosen ids list, make and return an array with its texts,
            e.g. ["Tag1", "Tag2"]
            */
            let available = this.available;
            return $.map(this.chosen, function(tagId) {
                let tag = available.find(tag => tag.id === tagId);
                if (tag) {
                    return tag.text
                }
            });
        },
        getTagObjectFromString(tagString) {
            let tag = this.available.find(tag => tag.text === tagString);
            return tag;
        },
        addTagToChosen: function(tag) {
            /* Add a tag to the objects chosen list. */
            if (typeof(tag) === 'string') {
                tag = this.getTagObjectFromString(tag)
            }
            this.chosen.push(tag.id);
        },
        addTagToNew: function(tag) {
            /* Add a tag to the objects newTags list. */
            this.new_tags.push(tag);
        },
        removeTag: function(tagRemoved) {
            /* Remove a tag from the objects chosen or new list. */
            let availableTag = this.available.find(tag => tag.text === tagRemoved);
            if (availableTag) {
                let tagIndex = this.chosen.indexOf(availableTag.id);
                this.chosen.splice(tagIndex, 1);
            } else {
                let tagIndex = this.new_tags.indexOf(tagRemoved);
                this.new_tags.splice(tagIndex, 1);
            }
        },
        fixTagsInputPosition: function() {
            /*
            Fixes the position of the text box when the tags are added or
            removed.
            */
            var pos = $('.tt-input').position();
            $('.tt-hint').css({
                'position': 'absolute',
                'top': pos.top,
                'left': pos.left
            })
        },
        onlyTagsList: function() {
            if (this.allow_new) {
                return false
            } else {
                return true
            }
        }
	},
    mounted: function () {
        let self = this;

        // Init Bloodhound data source for the tags.
        let tags = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            local: self.available,
        });
        let getTags = tags.initialize();

        // Container $el to variable.
        let $tags = $("#tags");
        $tags.attr("placeholder", "Enter " + self.name);

        // Init Typeahead.
        $tags.typeahead({
            highlight: true,
            hint: true,
        },{
            name: 'tags',
            displayKey: 'text',
            source: tags.ttAdapter(),
            templates: {header: '<h3>'+self.name+'</h3>'}
        }).on('typeahead:selected', function (e, tag) {
            // Push the tag to tag and clear value.
            if (!self.getChosenTagsText().includes(tag.text)) {
                self.addTagToChosen(tag);
                tagApi.tagsManager('pushTag', tag.text);
            }
            $tags.typeahead('val', '');
        });

        // Init TagManager.
        let tagApi = $tags.tagsManager({
            prefilled: self.getChosenTagsText(),
            onlyTagsList: self.onlyTagsList(),
            tagList: self.getAvailableTagsText(),
            tagCloseIcon: "<img src='/static/admin_v1/images/inline-delete-green.svg' />"
        });

        // Fix the Typeahead hint position.
        // At init, because we can have 'prefilled' tags, and on each refresh.
        self.fixTagsInputPosition();
        $tags.on('tm:refresh', function() {
            self.fixTagsInputPosition();
        });

        // If the tag is not a Typeahead suggested option, add the tags
        // as new one if not onlyTagsList.
        $tags.on('tm:pushed', function(e, tagString) {
            if (self.onlyTagsList()) {
                if (!self.getAvailableTagsText().includes(tagString)) {
                    tagApi.tagsManager('popTag');
                }
            } else {
                if (self.getAvailableTagsText().includes(tagString)) {
                    self.addTagToChosen(tagString);
                } else {
                    self.addTagToNew(tagString);
                }
                $tags.typeahead('val', '');
            }
        });

        // When remove a tag, we remove it from the chosen or new tags.
        $tags.on('tm:spliced', function(e, tag) {
            self.removeTag(tag);
            $tags.typeahead('val', '');
        });
    }
}
