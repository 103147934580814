
import AttrArrayTextInput from './AttrArrayTextInput.vue';
import AttrArrayTextArea from './AttrArrayTextArea.vue';
import AttrArrayNumberInput from './AttrArrayNumberInput.vue';
import AttrArraySelect from './AttrArraySelect.vue';
import AttrArrayRelationalRawId from './AttrArrayRelationalRawId.vue';

export default {
	name: "AttrArrayComplex",
	props: {
		'array_index': Number,
		// meta.sub_widgets: list of inner widgets meta
		'meta': Object,
		// data: complex attribute vue datadict
		// {'value': {'attr1': 'ES', 'attr2': 'Uno'}, 'errors': []}
		'data': Object,
	},
	components: {
		AttrArrayTextInput,
		AttrArrayTextArea,
		AttrArrayNumberInput,
		AttrArraySelect,
		AttrArrayRelationalRawId,
	},
}
