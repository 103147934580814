var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _c("span", { staticClass: "wrapper" }, [
      _vm.array_index === 0
        ? _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.meta.name_display)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.data.value.id,
            expression: "data.value.id",
          },
        ],
        ref: "valueId",
        staticClass: "vForeignKeyRawIdAdminField",
        attrs: {
          type: "text",
          name: "val",
          id: "id_" + _vm.meta.name + "_" + _vm.array_index,
        },
        domProps: { value: _vm.data.value.id },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.data.value, "id", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("a", {
        class: [
          { "has-thumbnail": _vm.data.value.asset_thumbnail_url },
          "related-lookup",
        ],
        attrs: {
          href: _vm.meta.url_related_list,
          id: "lookup_id_" + _vm.meta.name + "_" + _vm.array_index,
        },
      }),
      _vm._v(" "),
      _vm.data.value.name
        ? _c("strong", [
            _c("a", { attrs: { href: _vm.data.value.related_link } }, [
              _vm._v(_vm._s(_vm.data.value.name)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.value.asset_thumbnail_url
        ? _c(
            "a",
            {
              staticClass: "asset-file",
              attrs: { href: _vm.data.value.asset_url },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "40px",
                    height: "auto",
                    margin: "0 1ch 0",
                  },
                },
                [
                  _vm.data.value.is_video
                    ? _c("video", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          poster: _vm.data.value.poster,
                          src: _vm.data.value.asset_thumbnail_url,
                        },
                      })
                    : _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.data.value.asset_thumbnail_url },
                      }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }