var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widgetClassiticationSelector" }, [
    _c("div", { staticClass: "ClassificationSelector" }, [
      _c("div", { staticClass: "list-items" }, [
        _vm.aClassifications.length !== 0
          ? _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.showModalClassificationSelector(
                      _vm.aClassifications.name_id
                    )
                  },
                },
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.aClassifications.name_id))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.aClassifications.name_display))]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: "/static/admin_v2/images/windows.svg",
                    width: "16",
                    alt: "",
                  },
                }),
              ]
            )
          : _c(
              "button",
              {
                staticClass: "type-add",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.addItem()
                  },
                },
              },
              [
                _vm._v("\n                    Add item "),
                _c("img", {
                  attrs: {
                    src: "/static/admin_v2/images/plus-active.svg",
                    width: "18",
                    alt: "",
                  },
                }),
              ]
            ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isValidNodeDepth,
              expression: "!isValidNodeDepth",
            },
          ],
          staticClass: "warning-msg",
        },
        [
          _vm._v(
            "\n                WARNING: You have selected a level " +
              _vm._s(_vm.nodeDepth) +
              " Product Classification node. Please consider that for the later publisihing of this product and the articles linked it will be required a level " +
              _vm._s(_vm.requiredNodeDepth) +
              " or higher node classification.\n            "
          ),
        ]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value_selected.guid,
            expression: "value_selected.guid",
          },
        ],
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.value_selected.guid },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.value_selected, "guid", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "hidden Modal-transition",
        attrs: {
          id: "widget-classification-selector-modal",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "Modal-common modal-mask type-related" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c("div", { staticClass: "modal-header" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("h2", [_vm._v("Product Classification")]),
                _vm._v(" "),
                _vm.selectedValue
                  ? _c("div", { staticClass: "mb-25" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedValue.name_display,
                            expression: "selectedValue.name_display",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.selectedValue.name_display },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.selectedValue,
                              "name_display",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("form", [
                  _c("div", { staticClass: "table-head" }, [
                    _c("p", { staticClass: "col-id" }, [
                      _c("label", { attrs: { for: "name_id" } }, [
                        _vm._v("Name ID"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.varSearchId,
                            expression: "varSearchId",
                          },
                        ],
                        attrs: { type: "text", id: "name_id" },
                        domProps: { value: _vm.varSearchId },
                        on: {
                          keyup: _vm.classificationFilters,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.varSearchId = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "col-name" }, [
                      _c("label", { attrs: { for: "classification_name" } }, [
                        _vm._v("Classification Name"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.varSearchName,
                            expression: "varSearchName",
                          },
                        ],
                        attrs: { type: "text", id: "classification_name" },
                        domProps: { value: _vm.varSearchName },
                        on: {
                          keyup: _vm.classificationFilters,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.varSearchName = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(" ")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-cn scroll-cn" },
                  _vm._l(_vm.listClassifications, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "a",
                        {
                          staticClass: "table-row",
                          class:
                            _vm.selectedIndex === item.name_id
                              ? "activeClassifications"
                              : "",
                          attrs: { href: "#" },
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "col-id",
                              on: {
                                click: function ($event) {
                                  return _vm.selectCategory(item, index, true)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.name_id))]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "col-name",
                              on: {
                                click: function ($event) {
                                  return _vm.openChild("", index)
                                },
                              },
                            },
                            [
                              !_vm.showChild["child-" + index]
                                ? _c("img", {
                                    staticClass: "mr-10",
                                    attrs: {
                                      src: "/static/admin_v2/images/folder-plus.svg",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showChild["child-" + index]
                                ? _c("img", {
                                    staticClass: "mr-10",
                                    attrs: {
                                      src: "/static/admin_v2/images/folder-minus-active.svg",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n                                        " +
                                  _vm._s(item.name_display)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "select-classification n1",
                              on: {
                                click: function ($event) {
                                  return _vm.selectCategory(item, index, true)
                                },
                              },
                            },
                            [_vm._v("Select")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showChild["child-" + index],
                              expression: "showChild['child-' + index]",
                            },
                          ],
                          staticClass: "ch-n1",
                        },
                        _vm._l(item.children, function (item2, index2) {
                          return _c("li", { key: index2 }, [
                            _c(
                              "a",
                              {
                                staticClass: "table-row",
                                class:
                                  _vm.selectedIndex === item2.name_id
                                    ? "activeClassifications"
                                    : "",
                                attrs: { href: "#" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "col-id",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCategory(
                                          item2,
                                          index2,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item2.name_id))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "col-name",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openChild("n1", index2)
                                      },
                                    },
                                  },
                                  [
                                    !_vm.showChild["childn1-" + index2]
                                      ? _c("img", {
                                          staticClass: "mr-10",
                                          attrs: {
                                            src: "/static/admin_v2/images/folder-plus.svg",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showChild["childn1-" + index2]
                                      ? _c("img", {
                                          staticClass: "mr-10",
                                          attrs: {
                                            src: "/static/admin_v2/images/folder-minus-active.svg",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                                " +
                                        _vm._s(item2.name_display)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "p",
                                  {
                                    staticClass: "select-classification n2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCategory(
                                          item2,
                                          index2,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Select")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showChild["childn1-" + index2],
                                    expression:
                                      "showChild['childn1-' + index2]",
                                  },
                                ],
                                staticClass: "ch-n2",
                              },
                              _vm._l(item2.children, function (item3, index3) {
                                return _c(
                                  "li",
                                  {
                                    key: index3,
                                    staticClass: "table-row",
                                    class:
                                      _vm.selectedIndex === item3.name_id
                                        ? "activeClassifications"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectCategory(item3, index3)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "table-row",
                                        class:
                                          _vm.selectedIndex === item.name_id
                                            ? "activeClassifications"
                                            : "",
                                        attrs: { href: "#" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "col-id",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCategory(
                                                  item3,
                                                  index3,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(item3.name_id))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "col-name",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openChild(
                                                  "n1",
                                                  index2 + "-" + index3
                                                )
                                              },
                                            },
                                          },
                                          [
                                            item3.children &&
                                            !_vm.showChild[
                                              "childn1-" + index2 + "-" + index3
                                            ]
                                              ? _c("img", {
                                                  staticClass: "mr-10",
                                                  attrs: {
                                                    src: "/static/admin_v2/images/folder-plus.svg",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item3.children &&
                                            _vm.showChild[
                                              "childn1-" + index2 + "-" + index3
                                            ]
                                              ? _c("img", {
                                                  staticClass: "mr-10",
                                                  attrs: {
                                                    src: "/static/admin_v2/images/folder-minus-active.svg",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(
                                              "\n                                                        " +
                                                _vm._s(item3.name_display)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "select-classification n2",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCategory(
                                                  item3,
                                                  index3,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Select")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item3.children
                                      ? _c(
                                          "ul",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.showChild[
                                                    "childn1-" +
                                                      index2 +
                                                      "-" +
                                                      index3
                                                  ],
                                                expression:
                                                  "showChild['childn1-' + index2+'-'+index3]",
                                              },
                                            ],
                                            staticClass: "ch-n3",
                                          },
                                          _vm._l(
                                            item3.children,
                                            function (item4, index4) {
                                              return _c("li", { key: index4 }, [
                                                _c(
                                                  "p",
                                                  { staticClass: "col-id" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item4.name_id)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "col-name" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(item4.name_display)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "select-classification n3",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectCategory(
                                                          item4,
                                                          index4,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Select")]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        }),
                        0
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c("div", { staticClass: "c-l" }, [
                  _c("p", [
                    _c(
                      "button",
                      {
                        staticClass: "btn",
                        class: _vm.activeFilters != "" ? "btn-submit" : "",
                        attrs: { type: "button" },
                        on: { click: _vm.cleanFilters },
                      },
                      [_vm._v("Clear filters")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "c-r" }, [
                  _c("p", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-blue-light btn-cancel",
                        attrs: { type: "button" },
                        on: { click: _vm.hiddenClassificationModal },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "popup-close",
        attrs: { type: "button", id: "widget-classification-selector-close" },
      },
      [
        _c("img", {
          attrs: {
            src: "/static/admin_v2/images/close.svg",
            width: "24",
            alt: "close",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("label", [_vm._v("Current value:")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }