var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "has-language" },
    _vm._l(_vm.meta.sub_widgets, function (submeta, sub_widget_index) {
      return _c(
        "span",
        {
          class: [
            { "span-text": submeta.component === "AttrArrayTextInput" },
            { "span-textarea": submeta.component === "AttrArrayTextArea" },
          ],
        },
        [
          _c(submeta.component, {
            tag: "component",
            attrs: {
              array_index: _vm.array_index,
              meta: submeta,
              data: _vm.data.value[submeta.name],
            },
          }),
          _vm._v(" \n\t"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }