var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AdminTags field-tags", attrs: { id: "id_field_tags" } },
    [
      _c("admin-tags-widget", {
        attrs: {
          name: _vm.name,
          available: _vm.available,
          chosen: _vm.chosen,
          allow_new: _vm.allow_new,
          new_tags: _vm.new_tags,
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.chosenJson,
            expression: "chosenJson",
          },
        ],
        attrs: { type: "hidden", id: "id_" + _vm.name, name: _vm.name },
        domProps: { value: _vm.chosenJson },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.chosenJson = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _vm.allow_new
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newJson,
                expression: "newJson",
              },
            ],
            attrs: { type: "hidden", name: "new_" + _vm.name },
            domProps: { value: _vm.newJson },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.newJson = $event.target.value
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }