
export default {
	name: "AttrArraySelect",
	props: {
		'array_index': Number,
		// meta.options: list of options: {value: <value>, label: <text>}
		'meta': Object, // vue widget context
		'data': Object, // vue data context
	}
}
