

    let get_empty_search =  function(catalogs, catalogUUID) {
        catalogUUID = catalogUUID || null;
        return {
            query_data: {
                catalog: {
                    'uuid': catalogUUID, 'error': null
                },
                attributes: [get_empty_attribute(catalogs, catalogUUID)]
            },
            export_data: {
                'status': false,
                'structure': null,
                'error': null
            }
        }
    };

    let get_empty_attribute = function(catalogs, catalogUUID) {
        catalogs = catalogs || null;
        catalogUUID = catalogUUID || null;
        let attributeID = null;
        let subAttribute =  null;
        let lookup = null;
        let related = null;
        let kind = null;

        if (catalogs && catalogUUID) {
            attributeID = Object.keys(catalogs[catalogUUID].attributes)[0];
            related = catalogs[catalogUUID].attributes[attributeID].related;
            kind = catalogs[catalogUUID].attributes[attributeID].kind;
            lookup = catalogs[catalogUUID].attributes[attributeID].lookups[0];
            if (catalogs[catalogUUID].attributes[attributeID].sub_attr) {
                subAttribute = catalogs[catalogUUID].attributes[attributeID].sub_attr[0].name;
            }
        }
        return {
            'kind': kind,
            'id': attributeID,
            'type': 'any',
            'error': null,
            'condition': {
                'sub_attr': subAttribute,
                'related': related,
                'lookup': lookup,
                'value': null
            }
        }
    }

export default {
    name: 'SearchProductArticle',
    props: ['objCatalogs', 'objInitial', 'csrfToken'],
    data: () => ({
        'catalogs': null,
        'initial': null,
        'search': null,
        'query_data': {
            'catalog': {
                'uuid': null
            }
        },
        'export_data':{
            'structure': null
        }
    }),
    created () {
        this.catalogs = JSON.parse(this.objCatalogs);
        const initial = this.objInitial;

        if (initial) {
            this.initial = true;
            this.search = JSON.parse(initial);
            this.query_data = this.search.query_data;
            this.export_data = this.search.query_data;
        } else {
            this.initial = false;
            this.search = get_empty_search();
        }
    },
    methods: {
            change_catalog: function(catalog) {
                this.search = get_empty_search(this.catalogs, catalog);
                this.query_data = this.search.query_data;
                this.export_data = this.search.query_data;
                // console.log('-->' + this.search.query_data.catalog.uuid);
                
                if (this.initial){
                    var objects_list = document.getElementById('objects_list');
                    objects_list.innerHTML = '<tr class="row1"><th colspan="2">No Results</th></tr>';
                }
            },
            add_attribute: function(){
                this.query_data.attributes.push(
                    get_empty_attribute(this.catalogs, this.query_data.catalog.uuid)
                );
            },
            del_attribute: function(attr){
                this.query_data.attributes.splice(this.query_data.attributes.indexOf(attr), 1);
            },
            change_attribute: function(attr){
                let catalogUUID = this.query_data.catalog.uuid;
                let attributeID = attr.id;
                attr.type = 'any';
                attr.error = null;
                attr.condition.value = null;
                attr.condition.lookup = null;
                attr.condition.related = null;
                attr.condition.sub_attr = null;
                if (catalogUUID && attributeID) {
                    attr.kind = this.catalogs[catalogUUID].attributes[attributeID].kind;
                    attr.condition.related = this.catalogs[catalogUUID].attributes[attributeID].related;
                    attr.condition.lookup = this.catalogs[catalogUUID].attributes[attributeID].lookups[0];
                    if (this.catalogs[catalogUUID].attributes[attr.id].sub_attr) {
                        attr.condition.sub_attr = this.catalogs[catalogUUID].attributes[attr.id].sub_attr[0].name;;
                    }
                }
            },
            export_results: function(){
                console.log('-----------------');
                this.export_data.status = true;
                this.submit();
            },
            apply_search: function(){
                this.export_data.status = false;
                this.submit();
            },
            submit: function(){
                let validate = this.validate();
                if (validate) {
                    let data = JSON.stringify(this.search);
                    document.getElementById('id_data').value = data;
                    this.$refs.searchForm.submit();
                }
            },
            validate: function(){
                let validate = true;
                let required_msg = "This filter is not valid, please review it.";

                if (!this.query_data.catalog.uuid) {
                    validate = false;
                    this.query_data.catalog.error = required_msg;
                }else{
                    this.query_data.catalog.error = null
                }

                for (const attr in this.query_data.attributes) {
                    var attribute = this.query_data.attributes[attr];

                    if (!attribute.id) {
                        validate = false;
                        attribute.error = required_msg;
                    }else{
                        attribute.error = null;
                        if (this.catalogs[this.query_data.catalog.uuid].attributes[attribute.id].is_array) {
                            if (!attribute.type) {
                                validate = false;
                                attribute.error = required_msg;
                            }
                        }

                        if (this.catalogs[this.query_data.catalog.uuid].attributes[attribute.id].sub_attr) {
                            if (!attribute.condition.sub_attr) {
                                validate = false;
                                attribute.error = required_msg;
                            }
                        }

                        if (!attribute.condition.lookup || !attribute.condition.value) {
                            validate = false;
                            attribute.error = required_msg;
                        }
                    }
                }

                if (this.export_data.status && !this.export_data.structure) {
                    validate = false;
                    this.export_data.error = "This field is required.";
                }else{
                    this.export_data.error = null;
                }

                return validate;
            }
    }
}

