var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "wrapper" }, [
    _c("span", { class: [{ "mr-2": _vm.meta.name_display }, "label"] }, [
      _vm._v(_vm._s(_vm.meta.name_display)),
    ]),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.data.value,
            expression: "data.value",
          },
        ],
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.$set(
              _vm.data,
              "value",
              $event.target.multiple ? $$selectedVal : $$selectedVal[0]
            )
          },
        },
      },
      _vm._l(_vm.meta.options, function (option) {
        return _c("option", { domProps: { value: option.value } }, [
          _vm._v(_vm._s(option.label)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }