import AdminTagsWidget from './components/AdminTagsWidget.vue';
import AdminTagsWidgetWrapper from './components/AdminTagsWidgetWrapper.vue';

import AttrArrayRootWidget from './components/AttrArrayRootWidget.vue';
import AttrArrayComplex from './components/AttrArrayComplex.vue';
import AttrArrayNumberInput from './components/AttrArrayNumberInput.vue';
import AttrArrayRelationalRawId from './components/AttrArrayRelationalRawId.vue';
import AttrArraySelect from './components/AttrArraySelect.vue';
import AttrArrayTextInput from './components/AttrArrayTextInput.vue';
import AttrArrayTextArea from './components/AttrArrayTextArea.vue';

import SearchProductArticle from './components/SearchProductArticle.vue';
import SelectProductDatabase from './components/SelectProductDatabase.vue';

import ClassificationSelector from './components/ClassificationSelector.vue';


export {
	AdminTagsWidget,
	AdminTagsWidgetWrapper,

	AttrArrayRootWidget,
	AttrArrayComplex,
	AttrArrayNumberInput,
	AttrArrayRelationalRawId,
	AttrArraySelect,
	AttrArrayTextInput,
	AttrArrayTextArea,

	SearchProductArticle,
	SelectProductDatabase,

	ClassificationSelector
};
