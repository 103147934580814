


export default {
	name: "AttrArrayRelationalRawId",
	props: {
		'array_index': Number,
		'meta': Object, // vue widget context
		'data': Object, // vue data context
	}
}
