var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "wrapper" }, [
    _vm.array_index === 0
      ? _c("span", { staticClass: "label" }, [
          _vm._v(_vm._s(_vm.meta.name_display)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.data.value,
          expression: "data.value",
        },
      ],
      attrs: { type: "number" },
      domProps: { value: _vm.data.value },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.data, "value", $event.target.value)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }