


export default {
    name: 'ClassificationSelector',
    props: [
        'name', // django form input element name
        'name_id', // django form input element ID
        'required_node_depth', // django form input element ID
        'json_value_id', // json value from django script tag ID
        'json_choices_id', // json choices from django script tag ID
        'json_choices_tree_id' // json choices from django script tag ID
    ],
    data: () => ({
        aClassifications : [],
        selectedValue : [],
        selectedIndex : '',
        aClassificationsOptions: [],
        newClassification: [],
        styleClassifications: '',
        varSearchId: '',
        varSearchName: '',
        activeFilters: '',
        activeApplyButton: false,
        totalshowResults: '',
        aClassificationsOptionsTree: [],
        showChild: {},
        value_selected: {
            guid: ''
        },
        listClassifications: {},
        classificationFilter: [],
        requiredNodeDepth: null,
        nodeDepth: null,
        isValidNodeDepth: true
    }),
    created () {
        const ref = this;

        // Load value from Django.
        this.requiredNodeDepth = parseInt(this.required_node_depth);

        let objClassificationsSource = document.getElementById(this.json_value_id);
        let objClassifications = JSON.parse(objClassificationsSource.innerHTML);
        
        // Load choices from Django.
        // [{
        //     name_id: (str),
        //     name_display: (str),
        //     children: (obj)
        // }, ...]

        let objClasificationsTreeOptionsSource = document.getElementById(this.json_choices_tree_id);
        let objClasificationsTreeOptions = JSON.parse(objClasificationsTreeOptionsSource.innerHTML);
        
        this.aClassificationsOptionsTree = JSON.parse(objClasificationsTreeOptions).children
        this.listClassifications = this.aClassificationsOptionsTree

        if (objClassifications.name_id) {
            this.aClassifications = objClassifications
            this.value_selected.guid = "[\"" + objClassifications.guid + "\"]"

            this.validateNodeDepth(objClassifications)
        }

    },
    mounted (){
        const closeButtonWidgetSelector = document.getElementById('widget-classification-selector-close');
        closeButtonWidgetSelector.addEventListener('click', function () {
            let element = document.getElementById('widget-classification-selector-modal');
            element.classList.add('hidden');
        })
    },
    methods: {
        //show modal for add item
        addItem: function(){
            this.selectedValue = ''
            this.selectedIndex = ''
            this.styleClassifications=''
            this.showModalClassificationSelector()
        },
        //show modal, with data for edit or empty for add
        showModalClassificationSelector: function(index = -1) {
            function searchTree(element, matching){
                var stack = [], node, i;
                stack.push(element);
                while (stack.length > 0) {
                    node = stack.pop();
                    if (node.name_id == matching) {
                        return node;
                    } else if (node.children && node.children.length) {
                        for (i = 0; i < node.children.length; i += 1) {
                            stack.push(node.children[i]);
                        }
                    }
                }
                return null;
            }

            this.cleanFilters()
            this.listClassifications = this.aClassificationsOptionsTree

            if(index !== -1 && index !== ''){
                this.selectedValue = searchTree({'name_id': '', 'children':this.listClassifications}, index)

                this.styleClassifications= this.selectedValue.name_id
                this.selectedIndex = index
            } else{
                this.selectedValue = ''
            }

            let element = document.getElementById('widget-classification-selector-modal')
            element.classList.remove('hidden')
        },
        //save category selected and apply styles
        selectCategory: function(item, index, apply = 'false'){
            if(item){
                this.styleClassifications=item.name_id
            }
           if(apply === true){
            //    this.applySelectCategory()
            this.value_selected.guid = "[\"" + item.guid + "\"]"
            this.aClassifications = item
            this.hiddenClassificationModal()

            this.validateNodeDepth(item)
           }
        },
        //function for hidden modal
        hiddenClassificationModal: function(){
            let element = document.getElementById('widget-classification-selector-modal')
            element.classList.add('hidden')
        },

        //show classifications by id or name filters
        classificationFilters: function(){
            this.classificationFilter = []
            let ref = this
                for(let oneCl of this.aClassificationsOptionsTree){
                    let num = oneCl.name_id.toString().toLowerCase()
                    let nom = oneCl.name_display.toString().toLowerCase()
                    this.filterFunction(num, nom, oneCl )
                    if(oneCl.children){
                       oneCl.children.forEach(function(dat, index, object) {
                            let numCh1 = dat.name_id.toString().toLowerCase()
                            let nomCh1 = dat.name_display.toString().toLowerCase()
                            ref.filterFunction(numCh1, nomCh1, dat )
                            if(dat.children){
                                dat.children.forEach(function(da, index, obj) {
                                    let numCh2 = da.name_id.toString().toLowerCase()
                                    let nomCh2 = da.name_display.toString().toLowerCase()
                                    ref.filterFunction(numCh2, nomCh2, da )
                                });
                            }
                       });
                    }

                }

                if (this.varSearchId !== '' || this.varSearchName !== '' ){
                    this.totalshowResults = this.classificationFilter.length
                    this.listClassifications = this.classificationFilter
                } else{
                    this.totalshowResults = this.aClassificationsOptionsTree.length
                    this.listClassifications = this.aClassificationsOptionsTree
                }
        },
        filterFunction (num = '', nom = '', oneCl = '') {
            if(this.varSearchId){
                this.activeFilters = true
                if(num.indexOf(this.varSearchId.toLowerCase()) >=0 ){
                    if(this.varSearchName){
                        if(nom.indexOf(this.varSearchName.toLowerCase()) >=0 ){
                            this.classificationFilter.push(oneCl)
                        }
                    }else{
                        this.classificationFilter.push(oneCl)
                    }

                }

            }else if(this.varSearchName){
                this.activeFilters = true
                if(nom.indexOf(this.varSearchName.toLowerCase()) >=0 ){
                    this.classificationFilter.push(oneCl)
                }
            }else{
                this.activeFilters = false
            }
        },
        //initialize selected vars
        cleanFilters: function() {
            this.varSearchId = ''
            this.varSearchName = ''
            this.activeFilters = ''
            if(this.selectedValue){
                this.styleClassifications = this.selectedValue.name_id
            }else{
                this.styleClassifications =''
            }
            this.classificationFilters()
            this.activeApplyButton = false
        },
        validateNodeDepth: function(node) {
            this.nodeDepth = node.depth
            if (node.depth < this.requiredNodeDepth) {
                this.isValidNodeDepth = false;
            } else {
                this.isValidNodeDepth = true;
            }
        },

        openChild(nivel, id){
            var option = true
            if (this.showChild['child' + nivel +'-' + id]) {
                option = !this.showChild['child' + nivel +'-' + id]
            }
            this.$set(this.showChild, 'child' + nivel +'-' + id, option)
        }

    },
    computed: {
	},
}
