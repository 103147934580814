var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "tags-container" }, [
        _c("input", {
          staticClass: "tags-input",
          attrs: { id: "tags", type: "text" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }