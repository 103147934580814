

export default {
    name: 'SelectProductDatabase',
    props: ['title', 'objCatalogs', 'objInitial'],
    data: () => ({
        'aData' : [{'uuid': null, 'label': 'Select Catalog'}], // guardamos las opciones del select.
        'uuid': null, // di del catálogo
        'initial': null, // true = tiene búsqueda
        'error': null 
    }),
    created () {
        const catalogs = JSON.parse(this.objCatalogs);
        const initial = this.objInitial;
        const ref = this;

        Object.keys(catalogs).map(function(key) {
            // console.log('key: ' +  key + ', label: ' + catalogs[key].name_display)
            ref.aData.push({'uuid': key, 'label': catalogs[key].name_display});
        });
        
        if (initial) {
            this.initial = true;
            const search = JSON.parse(initial);
            const catalog = search.query_data.catalog;
            console.log('--', search)
            this.uuid = catalog.uuid;
            this.error = catalog.error;
        }
    },
    methods: {
        change_catalog: function() {
            console.log('component SelectProductDatabase -> uuid:' + this.uuid);
        }
    }
}
