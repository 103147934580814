
	import AdminTagsWidget from './AdminTagsWidget.vue';
	
    export default {
		name: 'AdminTagsWidgetWrapper',
		components: { 
			'AdminTagsWidget': AdminTagsWidget
		},
		props: ['jsonName', 'jsonContext'],
        data: () => ({
            'name': null,
			'available': null,
			'chosen': {},
            'allow_new': null,
			'new_tags': []
        }),
		computed: {
			chosenJson: function() {
				return JSON.stringify(this.chosen);
			},
            newJson: function() {
                return JSON.stringify(this.new_tags);
            }
		},
		created () {
			let name = (this.jsonName) ? this.jsonName : '';
			let vue_context = (this.jsonContext) ? JSON.parse(this.jsonContext) : {};

			this.name = name;
			this.available = vue_context.available;
			this.chosen = vue_context.chosen;
            this.allow_new = vue_context.allow_new_tags;

			$('.field-tags').parent().parent().css('overflow', 'visible');
		}
    }
