
import AttrArrayComplex from './AttrArrayComplex.vue';
import AttrArrayNumberInput from './AttrArrayNumberInput.vue';
import AttrArrayRelationalRawId from './AttrArrayRelationalRawId.vue';
import AttrArraySelect from './AttrArraySelect.vue';
import AttrArrayTextInput from './AttrArrayTextInput.vue';
import AttrArrayTextArea from './AttrArrayTextArea.vue';


export default {
	name: "AttrArrayRootWidget",
	components: {
		AttrArrayTextInput,
		AttrArrayTextArea,
		AttrArrayNumberInput,
		AttrArraySelect,
		AttrArrayComplex,
		AttrArrayRelationalRawId,
	},
	props: {
	    "name": String,
		"name_id": String,
	    // Element id with the context from Django as JSON.
		"context_id": String,
	},
	data: function() {
	    return {
	        // Django Vue Widget context.
			// meta.sub_widget: inner widget meta
			// data: full vue data, list of vue datadicts
			//
			// {
			//    "meta": self.get_vue_meta(),
			//    "data": self.datadict_from_value(value),
			// }
			//
	        "context": null
		}
	},
	methods: {
		get_default_data: function() {
			let default_data = this.context.meta.sub_widget.default_data;
			// Why do we have to do this?
			return JSON.parse(JSON.stringify(default_data));
		},
		add_item: function() {
            // console.log("Adding item with defaults");
			let default_data = this.get_default_data();
            // console.log(default_data);
			this.context.data.value.push(default_data);
		},
		remove_item: function(index) {
			// console.log("Removing item");
			// console.log(this.context.data.value[index]);
			this.context.data.value.splice(index, 1);
			if(this.context.data.value.length == 0){
				this.add_item()
			}
		},

	},
	computed: {
	    data_json: function() {
			return JSON.stringify(this.context.data);
		}
	},
	created() {
	    // Load context from Django.
		let context_el = document.getElementById(this.context_id);
		let context = JSON.parse(context_el.innerHTML);

		// DEBUG
		// console.log("AttrArrayRootWidget.created()");
        // console.log("Loading context...");
		// console.log(context_el);
        // console.log(context);
		
		this.context = context;
		if(context.data.value.length === 0) {
			this.add_item()
		}
		
	},

}
