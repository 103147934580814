var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      { staticClass: "pad-l-0" },
      _vm._l(_vm.context.data.value, function (data, array_index) {
        return _c(
          "li",
          [
            _c(_vm.context.meta.sub_widget.component, {
              tag: "component",
              attrs: {
                array_index: array_index,
                meta: _vm.context.meta.sub_widget,
                data: data,
              },
            }),
            _vm._v(" "),
            array_index === _vm.context.data.value.length - 1 &&
            _vm.context.meta.sub_widget.sub_widgets
              ? _c(
                  "span",
                  {
                    staticClass: "gtext-xs",
                    staticStyle: { cursor: "pointer", display: "block" },
                    on: { click: _vm.add_item },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/admin_v2/images/plus-active.svg",
                        alt: "Add",
                      },
                    }),
                    _vm._v(" Add language\n\t\t\t"),
                  ]
                )
              : array_index === _vm.context.data.value.length - 1 &&
                !_vm.context.meta.sub_widget.sub_widgets
              ? _c("span", [
                  _c(
                    "span",
                    {
                      staticClass: "gtext-xs",
                      staticStyle: { cursor: "pointer", display: "block" },
                      on: { click: _vm.add_item },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/static/admin_v2/images/plus-active.svg",
                          alt: "Add",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  data.value !== "" && data.value.id !== ""
                    ? _c(
                        "span",
                        {
                          staticClass: "gtext-xs",
                          staticStyle: { cursor: "pointer", display: "block" },
                          on: {
                            click: function ($event) {
                              return _vm.remove_item(array_index)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/admin/img/inline-delete.svg",
                              alt: "Remove",
                              width: "21",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c(
                  "span",
                  {
                    staticStyle: { cursor: "pointer", display: "block" },
                    on: {
                      click: function ($event) {
                        return _vm.remove_item(array_index)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/admin/img/inline-delete.svg",
                        alt: "Remove",
                      },
                    }),
                  ]
                ),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.data_json,
          expression: "data_json",
        },
      ],
      attrs: { type: "hidden", name: _vm.name },
      domProps: { value: _vm.data_json },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.data_json = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }